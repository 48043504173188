var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vertical-layout h-100",
      class: [_vm.layoutClasses],
      attrs: { "data-col": _vm.isNavMenuHidden ? "1-column" : null },
    },
    [
      _c(
        "b-navbar",
        {
          staticClass: "header-navbar navbar navbar-shadow align-items-center",
          class: [_vm.navbarTypeClass],
          attrs: { toggleable: false, variant: _vm.navbarBackgroundColor },
        },
        [
          _vm._t(
            "navbar",
            function () {
              return [
                _c("app-navbar-vertical-layout", {
                  attrs: {
                    "toggle-vertical-menu-active": _vm.toggleVerticalMenuActive,
                  },
                }),
              ]
            },
            {
              toggleVerticalMenuActive: _vm.toggleVerticalMenuActive,
              navbarBackgroundColor: _vm.navbarBackgroundColor,
              navbarTypeClass: _vm.navbarTypeClass.concat([
                "header-navbar navbar navbar-shadow align-items-center",
              ]),
            }
          ),
        ],
        2
      ),
      !_vm.isNavMenuHidden
        ? _c("vertical-nav-menu", {
            attrs: {
              "is-vertical-menu-active": _vm.isVerticalMenuActive,
              "toggle-vertical-menu-active": _vm.toggleVerticalMenuActive,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function (slotProps) {
                    return [
                      _vm._t("vertical-menu-header", null, null, slotProps),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        : _vm._e(),
      _c("div", {
        staticClass: "sidenav-overlay",
        class: _vm.overlayClasses,
        on: {
          click: function ($event) {
            _vm.isVerticalMenuActive = false
          },
        },
      }),
      _c(
        "transition",
        { attrs: { name: _vm.routerTransition, mode: "out-in" } },
        [
          _c(_vm.layoutContentRenderer, {
            key:
              _vm.layoutContentRenderer === "layout-content-renderer-left"
                ? _vm.$route.meta.navActiveLink || _vm.$route.name
                : null,
            tag: "component",
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.$scopedSlots, function (index, name) {
                  return {
                    key: name,
                    fn: function (data) {
                      return [_vm._t(name, null, null, data)]
                    },
                  }
                }),
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c(
        "footer",
        { staticClass: "footer footer-light", class: [_vm.footerTypeClass] },
        [
          _vm._t("footer", function () {
            return [_c("app-footer")]
          }),
        ],
        2
      ),
      _vm._t("customizer"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }